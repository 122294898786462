import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { BaseButton, IBaseButtonProps } from './BaseButton';

interface FilledButtonProps extends IBaseButtonProps {
    children?: ReactNode;
    onClick?: () => void;
    disabled?: boolean;
}

const Button = styled(BaseButton)`
    background: ${(props) => props.theme?.button?.primaryColor};
    border-radius: 25px;
    color: white;
    font-family: ${(props) => props.theme?.font?.type};
    font-size: 1rem;
    margin-top: 15px;
    margin-bottom: 20px;
    border: none;
    height: 46px;
    font-weight: ${(props) => props.theme?.components?.button?.fontWeight || 'normal'};
    width: 100%;
    transition: transform 0.2s ease-in;
    user-select: none;
    cursor: pointer;

    button&:disabled {
        background: ${(props) => props.theme?.button?.disabledColor};
        color: ${(props) => props.theme?.button?.disabledTextColor};
    }

    button&:active {
        transform: scale(0.96);
    }

    @media all and (max-width: 300px) {
        font-size: 0.8rem;
    }

    @media all and (max-width: 250px) {
        padding: 0.9375rem 1rem;
    }
`;

export const FilledButton = ({
    onClick,
    children,
    analyticEventName,
    disabled,
    analyticsExtras,
    className,
    ...props
}: FilledButtonProps) => (
    <Button
        onTouchStart={() => {}}
        className={className}
        onClick={onClick}
        analyticEventName={analyticEventName}
        analyticsExtras={analyticsExtras}
        disabled={disabled || false}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        {children}
    </Button>
);
