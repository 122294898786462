import { WebScanConfig } from '@home-mgmt-shared/web-scan';
import { ReactNode } from 'react';

export interface GenericResultsConfig {
    upsellComponent?: ReactNode;
    resultsHeader?: string;
    resultsSubHeader?: string;
    CustomHeaderComponent?: () => JSX.Element;
    checkSpeedAgainButtonDesktopColumn?: 'left' | 'right';
    expertCTADesktopColumn?: 'left' | 'right';
    ctaOverride?: ReactNode;
}

export interface GenericScanConfig {
    results?: GenericResultsConfig;
    webscan?: WebScanConfig;
}

export enum PageState {
    SCAN = 'scan',
    RESULTS = 'results',
}
