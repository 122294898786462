import { css } from '@emotion/react';

export const Snap = css`
    .snap-enter {
        opacity: 0;
        transform: scale(0.9);
        transform: translateY(50px);
    }
    .snap-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 1000ms, transform 1000ms;
    }
    .snap-exit {
        opacity: 1;
    }
    .snap-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 1000ms, transform 1000ms;
    }
`;
