import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Snap } from '../Animation';
import { IStreamingQualityGuideline, SpeedCheckup } from '../SpeedCheckup';

interface SpeedTestResultsProps {
    downloadSpeed?: number;
    uploadSpeed?: number;
    streamingGuidelines: IStreamingQualityGuideline[];
}

const SpeedTestResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    width: 100%;
    max-width: 365px;

    ${Snap}
`;

export const SpeedTestResults = ({
    downloadSpeed = 0,
    uploadSpeed = 0,
    streamingGuidelines,
}: SpeedTestResultsProps) => {
    const [render, setRender] = useState(false);

    useEffect(() => {
        setRender(true);
    }, []);
    return (
        <SpeedTestResultsContainer>
            <CSSTransition
                in={render}
                timeout={{
                    appear: 1000,
                    enter: 1000,
                }}
                appear
                unmountOnExit
                classNames="snap"
            >
                <SpeedCheckup
                    downloadSpeed={downloadSpeed}
                    uploadSpeed={uploadSpeed}
                    streamQualityGuidelines={streamingGuidelines}
                />
            </CSSTransition>
        </SpeedTestResultsContainer>
    );
};
