import { LifestyleIntro } from '@home-mgmt-shared/common-ui';
import { GenericScanConfig, GenericScanView, PageState } from '@home-mgmt-shared/generic-scan-page';
import React, { useCallback, useState } from 'react';
import { WifiSymbol } from '../assets/WifiSymbol';
import lifestyleImage from '../assets/yahoo_intro.png';
import { ResultsHeader, YahooUpsell } from '../components';
import { YahooDisclaimer } from '../components/YahooDisclaimer';
import { BasePage } from './BasePage';

const genericScanConfig: GenericScanConfig = {
    results: {
        upsellComponent: <YahooUpsell />,
        CustomHeaderComponent: () => ResultsHeader(),
        checkSpeedAgainButtonDesktopColumn: 'right',
        ctaOverride: YahooDisclaimer(),
    },
    webscan: {
        IntroComponent: ({ startSpeedTest }) =>
            LifestyleIntro({
                lifestyleImage,
                nextButtonOnClick: startSpeedTest,
                nextButtonText: 'Start speed test',
                headingText: 'Yahoo Plus Secure Wi-Fi Speed Test',
                subHeadingText: `Make sure you're connected to Wi-Fi before you start the speed test.`,
                onPageLoadAnalytic: 'Generic Web Scan Page',
                nextButtonAnalytic: 'Yahoo_Intro_Start_Btn',
                BackgroundCircleImage: WifiSymbol,
            }),
        scanningPage: {
            showHeader: true,
        },
    },
};

export const GenericScanPage = () => {
    const [hideFooter, setHideFooter] = useState(true);

    const onPageChange = useCallback((page) => {
        if (page === PageState.RESULTS) {
            setHideFooter(false);
        } else {
            setHideFooter(true);
        }
    }, []);

    return (
        <BasePage hideFooter={hideFooter}>
            <GenericScanView config={genericScanConfig} onPageChange={onPageChange} />
        </BasePage>
    );
};
