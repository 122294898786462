import { WixiCore } from '@soluto-private/wixi-web-sdk';
import { config } from './config';

export const initializeWixiSdk = (): void => {
    WixiCore.initialize({
        wixiPartner: {
            partnerSecret: config.wixiPartnerSecret,
            partnerAppId: config.wixiPartnerAppId,
            partnerId: config.wixiPartnerId,
            partnerAppSecret: config.wixiPartnerAppSecret,
        },
    });
};
