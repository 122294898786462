import * as React from 'react';

export const GoldStar = () => (
    <svg
        width={39}
        height={37}
        viewBox="0 0 39 37"
        fill="none"
        data-test-cy="GoldStar"
        role="img"
        aria-label="gold star"
    >
        <path
            d="M17.6251 1.43478C18.2477 -0.356344 20.7808 -0.356346 21.4033 1.43477L24.7 10.9189C24.9747 11.709 25.7121 12.2447 26.5484 12.2618L36.587 12.4664C38.4829 12.505 39.2656 14.9141 37.7546 16.0597L29.7534 22.1258C29.0868 22.6311 28.8051 23.498 29.0474 24.2987L31.9549 33.9092C32.504 35.7242 30.4547 37.2131 28.8983 36.13L20.6566 30.3949C19.97 29.9171 19.0585 29.9171 18.3719 30.3949L10.1302 36.13C8.57371 37.2131 6.52442 35.7242 7.07352 33.9092L9.98107 24.2987C10.2233 23.498 9.94165 22.6311 9.27505 22.1258L1.27387 16.0597C-0.237193 14.9141 0.545566 12.505 2.44141 12.4664L12.48 12.2618C13.3164 12.2447 14.0538 11.709 14.3284 10.9189L17.6251 1.43478Z"
            fill="#EDB800"
        />
    </svg>
);
