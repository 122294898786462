import React from 'react';
import styled from '@emotion/styled';

interface ITagButtonProps {
    tag: string;
    isSelected: boolean;
    onClick: (selected: string) => void;
    isVisible: boolean;
}

interface ISelected {
    isSelected: boolean;
}

const Button = styled.button<ISelected>`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    background-color: #ffffff;
    border-radius: 25px;
    border: ${(props) =>
        props.isSelected
            ? `2px solid ${props.theme.button?.primaryColor || ''}`
            : `1px solid ${props.theme.button?.disabledTextColor || ''}`};
    width: fit-content;
    padding: ${(props) => (props.isSelected ? '0.3375rem 0.7375rem;' : '0.4rem 0.80rem')};
    color: ${(props) => props.theme.button?.defaultTextColor};
    cursor: pointer;
    margin: 0.3125rem;
`;

export const TagButton = ({ tag, isSelected, onClick, isVisible }: ITagButtonProps) => (
    <Button tabIndex={isVisible ? 0 : -1} onClick={() => onClick(tag)} isSelected={isSelected}>
        {tag}
    </Button>
);
