import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import { useEffect } from 'react';

export const usePageAnalytics = (pageName?: string, customProperties = {}) => {
    useEffect(() => {
        if (pageName && pageName.length > 0) {
            analytics.updateProperties({ pageName });
            analytics.dispatch('PageLoaded', NSEventType.View, customProperties);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageName]);
};
