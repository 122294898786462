import { v4 } from 'uuid';

export const USER_BROWSER_LOCAL_STORAGE_KEY_NAME = 'user-browser-identifier';

export default class UserBrowser {
    public static get Id(): string {
        try {
            let sessionId: string | null = localStorage.getItem(USER_BROWSER_LOCAL_STORAGE_KEY_NAME);

            if (!sessionId) {
                sessionId = v4();
                localStorage.setItem(USER_BROWSER_LOCAL_STORAGE_KEY_NAME, sessionId);
            }

            return sessionId;
        } catch (exception) {
            return v4();
        }
    }
}
