import * as React from 'react';

export const GreenCheckmark = () => (
    <svg role="listitem" aria-label="Green checkmark icon" width="1em" height="1em" viewBox="0 0 21 21" fill="none">
        <path
            d="M5.158 11.484l4.043 4.043 5.858-8.201-2.035-1.453-4.142 5.799-1.957-1.956-1.767 1.768z"
            fill="#00B574"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.042 10.598c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10-10 4.486-10 10zm2 0c0-4.41 3.589-8 8-8s8 3.59 8 8c0 4.411-3.589 8-8 8s-8-3.589-8-8z"
            fill="#00B574"
        />
    </svg>
);
