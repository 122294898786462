import { FeedbackTagLists, IStreamingQualityGuideline } from '@home-mgmt-shared/common-ui';

export const shuffle = (array: string[]): string[] => {
    const sorted = [...array];
    for (let i = sorted.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = sorted[i];
        // eslint-disable-next-line no-param-reassign
        sorted[i] = sorted[j];
        // eslint-disable-next-line no-param-reassign
        sorted[j] = temp;
    }
    return sorted;
};

export const getDefaultFeedbackTags = (): FeedbackTagLists => ({
    maxScoreTagList: [
        'Fast results in app',
        'Easy to use',
        'Easy to understand',
        'Fixed my issue',
        'Learned something new',
    ],
    lowScoreTagList: [
        'Make it faster',
        'Make it easier to use',
        'Make it easier to understand',
        'Fix my issue',
        'Teach me something new',
    ],
});

export const getDefaultFeedbackMeta = (): FeedbackTagLists => ({
    headerMsgStart: 'How was your Wi-Fi checkup?',
    headerMsgComplete: 'Thanks for your feedback!',
    maxScoreTagList: [
        'Fast results in app',
        'Easy to use',
        'Easy to understand',
        'Fixed my issue',
        'Learned something new',
    ],
    lowScoreTagList: [
        'Make it faster',
        'Make it easier to use',
        'Make it easier to understand',
        'Fix my issue',
        'Teach me something new',
    ],
});

export const defaultStreamingGuidelines: IStreamingQualityGuideline[] = [
    {
        key: 'email',
        capability: 'Email',
        downloadReq: 1,
        uploadReq: 0,
    },
    {
        key: 'browseWeb',
        capability: 'Browse the web',
        downloadReq: 3,
        uploadReq: 0,
    },
    {
        key: 'gaming',
        capability: 'Game online',
        downloadReq: 5,
        uploadReq: 0,
    },
    {
        key: 'videoCall',
        capability: 'Video chat',
        downloadReq: 1,
        uploadReq: 1,
    },
    {
        key: 'videoCall',
        capability: 'Video conference',
        downloadReq: 5,
        uploadReq: 2,
    },
    {
        key: 'streamVideo',
        capability: 'Stream HD video on a single device',
        downloadReq: 5,
        uploadReq: 0,
    },
    {
        key: 'streamVideo',
        capability: 'Stream HD video on multiple devices',
        downloadReq: 10,
        uploadReq: 0,
    },
    {
        key: 'stream4k',
        capability: 'Stream 4k video on a single device',
        downloadReq: 25,
        uploadReq: 0,
    },
];

export const getDefaultSignalGuidelines = (): IStreamingQualityGuideline[] => [
    { capability: 'Email', downloadReq: 1, uploadReq: 0 },
    { capability: 'Browse the web', downloadReq: 3, uploadReq: 0 },
    { capability: 'Game online', downloadReq: 5, uploadReq: 0 },
    { capability: 'Video chat', downloadReq: 1, uploadReq: 0 },
    { capability: 'Video conference', downloadReq: 5, uploadReq: 0 },
    {
        capability: 'Stream HD video on a single device',
        downloadReq: 5,
        uploadReq: 0,
    },
    {
        capability: 'Stream HD video on multiple devices',
        downloadReq: 10,
        uploadReq: 0,
    },
    {
        capability: 'Stream 4k video on a single device',
        downloadReq: 25,
        uploadReq: 0,
    },
];
