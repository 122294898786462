/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { isMobile } from '../utils';
import { BaseButton } from './BaseButton';

type CallButtonProps = {
    phoneNumber: string;
    analyticEventName: string;
    buttonText?: string;
    content?: string;
    subContent?: string;
};

const markdownOverrides = css`
    /* override react markdown */
    p {
        margin: 0;
    }

    h2 {
        font-weight: inherit;
    }

    h3 {
        font-weight: inherit;
    }
`;

const Button = styled(BaseButton)`
    background: ${(props) => props.theme.button?.primaryColor};
    border-radius: 25px;
    color: white;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    margin-top: 15px;
    margin-bottom: 1.75rem;
    border: none;
    height: 46px;
    width: 100%;
    transition: transform 0.2s ease-in;
    user-select: none;

    button&:active {
        transform: scale(0.96);
    }
    button&: hover, ${markdownOverrides};
`;

const StaticButton = styled.div`
    background: ${(props) => props.theme.button?.primaryColor};
    border-radius: 25px;
    color: white;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    margin-top: 15px;
    margin-bottom: 1.75rem;
    border: none;
    height: 46px;
    width: 100%;
    user-select: none;
    text-align: center;
    line-height: 46px;

    ${markdownOverrides}
`;

const PhoneCallLink = styled.a`
    width: 100%;
`;

const PhoneCallWrapper = styled.div`
    width: 100%;
`;

const TextWrapper = styled.div`
    margin-bottom: 1.25rem;
    ${markdownOverrides}

    a {
        font-weight: bold;
        text-decoration: none;
        color: ${(props) => props.theme.button?.primaryColor};
    }
`;

export const CallButton = ({ buttonText, phoneNumber, content, analyticEventName, subContent }: CallButtonProps) => {
    const analyticExtras: Record<string, unknown> = { phoneNumber };

    return (
        <>
            {isMobile() ? (
                <>
                    <PhoneCallLink data-test-cy="call-expert-link" href={`tel:${phoneNumber}`}>
                        <Button
                            tabIndex={0}
                            data-test-cy="call-expert-text"
                            analyticEventName={analyticEventName}
                            analyticsExtras={analyticExtras}
                        >
                            {buttonText?.length ? <ReactMarkdown>{buttonText}</ReactMarkdown> : `Call ${phoneNumber}`}
                        </Button>
                    </PhoneCallLink>
                    {subContent && (
                        <TextWrapper>
                            <ReactMarkdown data-test-cy="call-expert-text">{subContent}</ReactMarkdown>
                        </TextWrapper>
                    )}
                </>
            ) : (
                <PhoneCallWrapper>
                    {content?.length ? (
                        <TextWrapper>
                            <ReactMarkdown data-test-cy="call-expert-text">{content}</ReactMarkdown>
                        </TextWrapper>
                    ) : (
                        <StaticButton tabIndex={0} data-test-cy="call-expert-text">
                            {buttonText?.length ? <ReactMarkdown>{buttonText}</ReactMarkdown> : `Call ${phoneNumber}`}
                        </StaticButton>
                    )}
                </PhoneCallWrapper>
            )}
        </>
    );
};
