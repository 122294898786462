import * as React from 'react';

export const GrayStar = () => (
    <svg
        width={39}
        height={37}
        viewBox="0 0 39 37"
        fill="none"
        data-test-cy="GrayStar"
        role="img"
        aria-label="gray star"
    >
        <path
            d="M17.5966 1.43478C18.2192 -0.356344 20.7523 -0.356346 21.3748 1.43477L24.6715 10.9189C24.9462 11.709 25.6835 12.2447 26.5199 12.2618L36.5585 12.4664C38.4544 12.505 39.2371 14.9141 37.7261 16.0597L29.7249 22.1258C29.0583 22.6311 28.7766 23.498 29.0189 24.2987L31.9264 33.9092C32.4755 35.7242 30.4262 37.2131 28.8697 36.13L20.6281 30.3949C19.9414 29.9171 19.03 29.9171 18.3434 30.3949L10.1017 36.13C8.54521 37.2131 6.49591 35.7242 7.04502 33.9092L9.95257 24.2987C10.1948 23.498 9.91314 22.6311 9.24655 22.1258L1.24537 16.0597C-0.265696 14.9141 0.517062 12.505 2.41291 12.4664L12.4515 12.2618C13.2879 12.2447 14.0253 11.709 14.2999 10.9189L17.5966 1.43478Z"
            fill="#C4C4C4"
        />
    </svg>
);
