import styled from '@emotion/styled';
import React from 'react';
import { BaseButton, IBaseButtonProps } from './BaseButton';

interface OutlineButtonProps extends IBaseButtonProps {
    onClick?: () => void;
    disabled?: boolean;
    borderwidth?: string;
}

interface BorderProps {
    borderwidth?: string;
}

const Button = styled(BaseButton)<BorderProps>`
    background: none;
    border-radius: 25px;
    color: ${(props) => props.theme.button?.primaryColor};
    padding: 0.78125rem 3rem;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    margin-top: 15px;
    border: ${(props) => props.borderwidth ?? '2px'} solid ${(props) => props.theme.button?.primaryColor};
    min-height: 46px;
    width: 100%;
    transition: transform 0.2s ease-in;
    user-select: none;
    font-weight: ${(props) => props.theme.components?.button?.fontWeight || 'normal'};
    cursor: pointer;

    button&:active {
        transform: scale(0.96);
    }
    @media (hover: hover) {
        button&:hover {
            border: 2px solid ${(props) => props.theme.button?.primaryColor};
        }
    }
`;

export const OutlineButton = ({
    onClick,
    children,
    analyticEventName,
    disabled,
    analyticsExtras,
    borderwidth,
    ...props
}: OutlineButtonProps) => (
    <Button
        onClick={onClick}
        analyticsExtras={analyticsExtras}
        analyticEventName={analyticEventName}
        disabled={disabled || false}
        borderwidth={borderwidth}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        {children}
    </Button>
);
