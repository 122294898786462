import React from 'react';
import styled from '@emotion/styled';
import { GreenCheckmark } from '../Icons';

interface SpeedTestListApprovedProps {
    listItem: string;
}

const SpeedTestResultListItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${(props) => props.theme.font?.type};
`;

const SpeedTestResultIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
`;

const SpeedTestResultList = styled.div`
    display: flex;
    margin-left: 3px;
    padding: 5px 0px;
    align-items: center;
`;
export const SpeedTestListApproved = ({ listItem }: SpeedTestListApprovedProps) => (
    <SpeedTestResultList>
        <SpeedTestResultIcon>
            <GreenCheckmark />
        </SpeedTestResultIcon>
        <SpeedTestResultListItem data-test-cy={`${listItem}-approved`}>{listItem}</SpeedTestResultListItem>
    </SpeedTestResultList>
);
