import * as React from 'react';

export const YellowAlertTriangle = () => (
    <svg role="listitem" aria-label="Warning icon" width="1em" height="1em" viewBox="0 0 20 21" fill="none">
        <path d="M11 8.803H9v6h2v-6zM11.25 16.803a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z" fill="#FFA700" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 20.803a1.003 1.003 0 01-.895-1.448l9-18c.34-.677 1.45-.677 1.79 0l9 18A1.003 1.003 0 0119 20.803H1zm16.382-2L10 4.04 2.618 18.803h14.764z"
            fill="#FFA700"
        />
    </svg>
);
