import React, { useCallback } from 'react';
import styled from '@emotion/styled';

interface IOpenFeedbackProps {
    onChange: (text: string) => void;
    placeHolderText?: string;
    isVisible: boolean;
}

const FeedbackContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
`;

const FeedbackText = styled.textarea`
    margin-top: 0.9375rem;
    width: 100%;
    height: 115px;
    resize: none;
    font-size: 0.875rem;
    font-family: ${(props) => props.theme.font?.type};
    border: 1px solid #6e767d;
    padding: 0.5rem;
    border-radius: 4px;
    margin-left: 1rem;
    margin-right: 1rem;
`;

export const OpenFeedback = ({ onChange, placeHolderText, isVisible }: IOpenFeedbackProps) => {
    const [value, setValue] = React.useState('');
    const changeDetected = useCallback(
        (val: string) => {
            setValue(val);
            onChange(val);
        },
        [onChange],
    );
    return (
        <FeedbackContainer data-test-cy="feedback-survey-textbox">
            <FeedbackText
                tabIndex={isVisible ? 0 : -1}
                value={value}
                placeholder={placeHolderText ?? 'Anything else?'}
                onChange={(v) => changeDetected(v.target.value)}
                maxLength={200}
            />
        </FeedbackContainer>
    );
};
