import React, { ButtonHTMLAttributes, ReactNode, useCallback } from 'react';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';

export interface IBaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    onClick?: () => void;
    children?: ReactNode;
    analyticsExtras?: Record<string, unknown>;
    analyticEventName?: string;
    disabled?: boolean;
}

export const BaseButton = ({
    onClick,
    children,
    analyticsExtras = {},
    analyticEventName,
    disabled,
    ...props
}: IBaseButtonProps) => {
    const performOnClick = useCallback(() => {
        if (disabled) return;

        if (onClick) {
            onClick();
        }
        if (analyticEventName) {
            analytics.dispatch(analyticEventName, NSEventType.Click, analyticsExtras);
        }
    }, [analyticEventName, analyticsExtras, disabled, onClick]);

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <button className={props.className} type="button" onClick={performOnClick} disabled={disabled} {...props}>
            {children}
        </button>
    );
};
