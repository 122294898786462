import * as React from 'react';

interface DownArrowProps {
    fillColor?: string;
}

export const DownArrow = ({ fillColor = '#000' }: DownArrowProps) => (
    <svg width={31} height={31} viewBox="0 0 31 31" fill="none" role="img" aria-label="down arrow">
        <circle opacity={0.1} r={15.022} transform="matrix(-1 0 0 1 15.8384 15.113)" fill={fillColor} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.9 18.7847V7.60156H16.7777V18.7838L20.1479 15.1125L21.4717 16.4363L15.8384 22.0696L10.2052 16.4363L11.529 15.1125L14.9 18.7847ZM15.8384 19.8069H15.8385L15.8384 19.807L15.8384 19.8069Z"
            fill={fillColor}
        />
    </svg>
);
