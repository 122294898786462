import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
    margin-top: 2rem;
`;

const Header = styled.div`
    font-size: 2rem;
    font-weight: bold;
`;

const Line = styled.hr`
    width: 100%;
    border: 1px solid #e6e6eb;
`;
export const ResultsHeader = () => (
    <Container>
        <Header>Your Results</Header>
        <Line />
    </Container>
);
