export const streamingQuality = [
    { stream: 'Email', downloadReq: 1, uploadReq: 0 },
    { stream: 'Browse the web', downloadReq: 3, uploadReq: 0 },
    { stream: 'Game online', downloadReq: 5, uploadReq: 0 },
    { stream: 'Video chat', downloadReq: 1, uploadReq: 1 },
    { stream: 'Video conference calls', downloadReq: 5, uploadReq: 2 },
    {
        stream: 'Stream HD video on a single device',
        downloadReq: 5,
        uploadReq: 0,
    },
    {
        stream: 'Stream 4k video on a single device',
        downloadReq: 25,
        uploadReq: 0,
    },
    {
        stream: 'Stream HD video on multiple devices',
        downloadReq: 10,
        uploadReq: 0,
    },
];

export const termsUrl = 'https://www.asurion.com/terms-conditions/';
export const privacyUrl = 'https://www.asurion.com/privacy-policy/';
export const mlabPrivacy = `https://www.measurementlab.net/privacy/`;
