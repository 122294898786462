import styled from '@emotion/styled';
import { BaseButton, BreakPoints, ChevronRight } from '@home-mgmt-shared/common-ui';
import React, { useCallback } from 'react';
import { YahooSecurePlusLogo } from '../assets';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-var-requires
const laptopImg: string | undefined = require('../assets/LaptopEdge.png');
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-var-requires
const xboxControllerImg: string | undefined = require('../assets/xbox.png');
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-var-requires
const appleWatchImg: string | undefined = require('../assets/watch.png');

const UpsellContainer = styled.div`
    border-radius: 4px;
    max-width: 365px;
    cursor: default;
    background-color: #f0f0f5;
    display: flex;
    overflow: hidden;
    max-height: 235px;
    cursor: pointer;

    @media ${BreakPoints.mobileMid} {
        max-height: 235px;
    }
`;

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 0;
    padding-top: 15px;
    padding-left: 15px;
    max-width: 300px;
`;
const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 386px;
    max-width: 115px;
    background-image: -webkit-linear-gradient(170deg, #ffffff 60%, #f0f0f5 30%);

    @media ${BreakPoints.mobileMid} {
        max-height: 320px;
    }
`;

const LogoContainer = styled.div`
    height: 25px;
    width: 72px;
`;

const LaptopContainer = styled.div`
    position: relative;
    left: 32px;
    @supports (-webkit-appearance: attachment) {
        left: 32px;
        top: 0;
        height: 250px;
    }
`;

const LaptopImg = styled.img`
    height: 150px;
`;

const AppleWatchContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    left: 38px;
    top: -41px;
    @supports (-webkit-appearance: attachment) {
        left: 38px;
        top: -45px;
    }
`;

const XboxControllerContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    left: -9px;
    top: -115px;

    @supports (-webkit-appearance: attachment) {
        left: -9px;
        top: -115px;
    }
`;

const XBoxImg = styled.img`
    height: 120px;
`;

const WatchImg = styled.img`
    height: 120px;
`;

const Heading = styled.h1`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1.125rem;
`;

const OfferDescription = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 0.8125rem;
    margin-bottom: 1.25rem;
`;

const LearnMoreButton = styled(BaseButton)`
    background: ${(props) => props.theme.button?.primaryColor};
    border-radius: 25px;
    color: white;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    font-weight: bold;
    border: none;
    height: 30px;
    max-width: 140px;
    margin-left: 15px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: transform 0.2s ease-in;
    user-select: none;

    button&:active {
        transform: scale(0.96);
    }
    button&:hover,
    button&:focus:active {
        outline: none;
    }

    margin-bottom: 1rem;
`;

export const YahooUpsell = () => {
    const onUpsellClick = useCallback(() => {
        window.open(
            'https://plans.yahoo.com/plans/yahoo-plus-secure?feature=assist&ncid=mbr_yplacqopf00000001 ',
            '_blank',
        );
    }, []);
    return (
        <UpsellContainer onClick={onUpsellClick}>
            <LeftColumn>
                <LogoContainer>
                    <YahooSecurePlusLogo />
                </LogoContainer>
                <Heading>Having trouble with your home tech?</Heading>
                <OfferDescription>
                    <strong>Yahoo Plus Secure</strong> includes 24/7, US-based tech support for your home technology.
                </OfferDescription>
                <LearnMoreButton onClick={onUpsellClick} analyticEventName="Upsell_Learn_More_Clicked">
                    Learn more &nbsp;&nbsp;&nbsp; <ChevronRight />
                </LearnMoreButton>
            </LeftColumn>
            <RightColumn>
                <LaptopContainer>
                    <LaptopImg src={laptopImg} alt="laptop" />
                </LaptopContainer>
                <AppleWatchContainer>
                    <WatchImg src={appleWatchImg} />
                </AppleWatchContainer>
                <XboxControllerContainer>
                    <XBoxImg src={xboxControllerImg} />
                </XboxControllerContainer>
            </RightColumn>
        </UpsellContainer>
    );
};
