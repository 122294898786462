import React from 'react';
import styled from '@emotion/styled';
import { TagButton } from './TagButton';

interface IFeedbackTagsProps {
    tagList: string[];
    onClick: (selected: string) => void;
    selectedTags: string[];
    isVisible: boolean;
}

const FeedbackTagsContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
`;

export const FeedbackTags = ({ tagList, onClick, selectedTags, isVisible }: IFeedbackTagsProps) => (
    <FeedbackTagsContainer data-test-cy="feedback-survey-tag-list">
        {tagList.map((tag) => (
            <TagButton
                isVisible={isVisible}
                key={tag}
                tag={tag}
                isSelected={selectedTags.includes(tag)}
                onClick={onClick}
            />
        ))}
    </FeedbackTagsContainer>
);
