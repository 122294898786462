import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled from '@emotion/styled';

const SpeedTestFont = styled.div`
    font-family: ${(props) => props.theme.font?.type};
`;

const SpeedProgressCircle = styled.div`
    max-width: 450px;

    width: 350px;
    height: 190px;

    path {
        stroke-width: 4;
    }

    @media all and (max-width: 450px) {
        width: 300px;
    }

    .CircularProgressbar-path {
        stroke: ${(props) => props.theme.special?.secondaryColor} !important;
    }

    .CircularProgressbar .CircularProgressbar-text {
        fill: ${(props) => props.theme.special?.primaryColor};
    }
`;

const styles = {
    rotation: 1 / 2 + 1 / 5,
    strokeLinecap: 'butt',
    trailColor: '#eee',
};

interface SpeedTestProgress {
    speed: number;
    status: string;
}

interface SpeedProgressProps {
    progressData: SpeedTestProgress;
}

const SpeedTestProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 290px;
    margin-bottom: 75px;
`;

const MpbsLabel = styled(SpeedTestFont)`
    display: flex;
    font-size: 16px;
    color: #000000;
    margin-top: 20px;
    @media all and (max-width: 450px) {
        margin-top: -20px;
    }
`;

const CircleNumbers = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 312px;
    font-size: 13px;
    color: #999999;
    margin-top: 5px;

    @media all and (max-width: 450px) {
        margin-top: 10px;
        width: 270px;
        font-size: 16px;
    }
`;

const ProgressStatus = styled(SpeedTestFont)`
    display: flex;
    font-size: 16px;
    text-align: center;
    color: black;
    margin-top: -15px;
    font-size: 16px;

    @media all and (max-width: 450px) {
        font-size: 14px;
    }
`;

export const SpeedProgress = ({ progressData }: SpeedProgressProps) => {
    const speedData = progressData.speed;
    const greaterThan100 = speedData > 100;
    return (
        <SpeedTestProgressContainer>
            <SpeedProgressCircle>
                {speedData <= 100 && (
                    <CircularProgressbar
                        value={speedData} // the actual progress
                        text={`${speedData}`}
                        circleRatio={0.6}
                        styles={buildStyles(styles)}
                    />
                )}
                {speedData > 100 && (
                    <CircularProgressbar
                        value={(speedData / 250) * 100} // the actual progress
                        text={`${speedData}`}
                        circleRatio={0.6}
                        styles={buildStyles(styles)}
                    />
                )}
            </SpeedProgressCircle>
            <MpbsLabel>Mbps</MpbsLabel>
            <CircleNumbers>
                <div>0</div>
                {!greaterThan100 && <div>100+</div>}
                {greaterThan100 && <div>250+</div>}
            </CircleNumbers>
            <ProgressStatus>{progressData.status}</ProgressStatus>
        </SpeedTestProgressContainer>
    );
};
