import * as React from 'react';

interface UpArrowProps {
    fillColor?: string;
}

export const UpArrow = ({ fillColor = '#000' }: UpArrowProps) => (
    <svg width={31} height={31} viewBox="0 0 31 31" fill="none" role="img" aria-label="up arrow">
        <circle
            opacity={0.1}
            cx={15.146}
            cy={15.113}
            r={15.022}
            transform="rotate(-180 15.146 15.113)"
            fill={fillColor}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.2076 11.4414V22.6245H16.0854V11.4423L19.4555 15.1135L20.7793 13.7897L15.1461 8.15649L9.51282 13.7897L10.8366 15.1135L14.2076 11.4414ZM15.146 10.4192H15.1461L15.1461 10.4191L15.146 10.4192Z"
            fill={fillColor}
        />
    </svg>
);
