import styled from '@emotion/styled';
import React from 'react';
import { YahooSecurePlusLogo } from '../assets';
import { AsurionLogo } from '../assets/AsurionLogo';
import { VerticalDivider } from '../assets/VerticalDivider';

const HeaderContainer = styled.div`
    width: 100%;
    border-top: 5px solid ${(props) => props.theme.accentColors?.primary};
    background: #fff;
    z-index: 200;

    cursor: default;
`;

const Logo = styled.div`
    display: flex;
    flex-direction: row;
`;

const LogoItem = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem;
    height: 30px;
    width: 90px;
`;

const HeaderContent = styled.div`
    height: 3.5rem;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
`;

const Divider = styled.div`
    margin-top: 0.75rem;
`;

export const YahooHeader = () => (
    <HeaderContainer>
        <HeaderContent>
            <Logo>
                <LogoItem>
                    <YahooSecurePlusLogo />
                </LogoItem>
                <Divider>
                    <VerticalDivider />
                </Divider>
                <LogoItem>
                    <AsurionLogo />
                </LogoItem>
            </Logo>
        </HeaderContent>
    </HeaderContainer>
);
