import { TweekProvider as Provider } from 'react-tweek';
import React from 'react';
import { useRxApi } from '@home-mgmt-shared/common-hooks';
import { defaultTweekRepository, tweekRepository$ } from './tweek';

interface TweekProviderProps {
    children: React.ReactNode;
}

export const TweekProvider = ({ children }: TweekProviderProps) => {
    const { result: tweekRepo } = useRxApi(tweekRepository$, {
        initialValue: defaultTweekRepository,
    });

    return <Provider value={tweekRepo}>{children}</Provider>;
};
