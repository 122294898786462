import { config } from './config';

export const APPLICATION_ID = 'applicationId';
export const AUTH_TOKEN = 'authToken';
export const USER_ID = 'userId';
export const SUB_ID = 'subscriberId';
export const FLOW_ID = 'flow';
export const CID = 'cid';
export const PROGRAM = 'program';
export const IS_TEST = 'isTest';

// app constants
export const PARTNER = 'yahoo';
export const APP_NAME = 'yahoo-dashboard';

export const isProduction = (): boolean => config.env === 'prod';
