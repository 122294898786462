interface SpeedTestProgressData {
    speed: number;
    status: string;
}

export const formatSpeed = (speed: string): number => {
    let numberSpeed = Math.round(parseFloat(speed.split(' ')[0]));
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(numberSpeed)) {
        numberSpeed = 0;
    }

    return numberSpeed;
};

export const getProgressData = (status: string, uploadSpeed: string, downloadSpeed: string): SpeedTestProgressData => {
    if (status === 'inProgressDownload') {
        return {
            speed: formatSpeed(downloadSpeed),
            status: 'Testing download speed',
        };
    }
    if (status === 'inProgressUpload') {
        return {
            speed: formatSpeed(uploadSpeed),
            status: 'Testing upload speed',
        };
    }
    return { speed: 0, status: 'Scan initializing' };
};
