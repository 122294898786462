/* eslint-disable no-console */
import { LogLevel } from './logLevels';

class LoggingApi {
    url: string;

    headers: Record<string, unknown>;

    // eslint-disable-next-line react/static-property-placement
    context: Record<string, unknown>;

    constructor(options: { url: string; headers: Record<string, unknown>; context: Record<string, unknown> }) {
        this.url = options.url;
        this.headers = options.headers;
        this.context = options.context;
    }

    async log(logLevel: LogLevel, logMessage: string, error?: string | Error, extraData?: Record<string, unknown>) {
        const apiUrl = extraData && extraData.url ? extraData.url : this.url;
        const appName = extraData && extraData.appName ? extraData.appName : 'home-network';
        let err = '';
        if (error) {
            if (error instanceof Error) {
                err = `Error: ${error.message}`;
            } else {
                err = `Error: ${error}`;
            }
        }

        const headers = {
            'Content-Type': 'application/json',
        };

        let data: Record<string, unknown> = {
            currentUrlPath: window.location.pathname,
            ...extraData,
        };

        if (err) {
            data = { ...data, err };
        }

        const body = JSON.stringify({
            message: logMessage,
            context: {
                // TODO - Add updateContext to each app to grab appName
                appName,
                currentUrl: window.location.href,
                ...this.context,
            },
            parameters: {
                ...data,
            },
        });

        try {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            const response = await fetch(`${apiUrl}${logLevel}`, {
                method: 'POST',
                headers,
                body,
            });

            if (!response.ok) {
                console.warn('LoggingApi Response indicates failure', response.status);
            }
        } catch (e) {
            console.warn('Error sending log to LoggingApi', e);
        }
    }

    updateContext(update: Record<string, unknown>) {
        this.context = { ...this.context, ...update };
    }
}

// change app id
const logger = new LoggingApi({
    url: '',
    headers: {},
    context: {},
});

export const updateLoggingApiContext = logger.updateContext.bind(logger);

export const logToLoggingApi = (
    level: LogLevel,
    message: string,
    error?: string | Error,
    data?: Record<string, unknown>,
) => logger.log(level, message, error, data);
