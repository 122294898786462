import styled from '@emotion/styled';
import React, { ReactNode, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

interface IconLinkProps {
    msg: string;
    Icon: JSX.Element | ReactNode;
    route: string;
    analyticEventName?: string;
    analyticsCb?: (eventName: string) => void;
}

const IconLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`;

const IconContainer = styled.div`
    display: flex;
    height: 1.5rem;
    width: 1.5rem;
`;

const LinkText = styled.div`
    font-size: 16px;
    padding-left: 0.5rem;
    text-decoration: underline;
`;

export const IconLink = ({ msg, Icon, route, analyticsCb, analyticEventName }: IconLinkProps) => {
    const history = useHistory();

    const onLinkClick = useCallback(() => {
        analyticsCb?.(analyticEventName || 'Icon_Link');
        history.push(route);
    }, [analyticEventName, analyticsCb, history, route]);

    return (
        <IconLinkContainer>
            <IconContainer>{Icon}</IconContainer>
            <LinkText onClick={onLinkClick}>{msg}</LinkText>
        </IconLinkContainer>
    );
};
