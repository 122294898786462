import styled from '@emotion/styled';
import { CallButton, FilledButton } from '@home-mgmt-shared/common-ui';
import React, { useCallback, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { ContactExpert } from './ContactExpert';
import { ButtonCTATypes, IExpertCTAOptions } from './ExpertCTAInfo.interface';

type ContactExpertCTAProps = {
    options: IExpertCTAOptions;
    analyticEventName: string;
};

interface VisibilityProps {
    isVisible: boolean;
}
const VisibilityWrapper = styled.div`
    transition: max-height 1s ease-in-out;
    transition-delay: 1s;
    height: auto;
    overflow: hidden;
    max-height: ${(props: VisibilityProps) => (props.isVisible ? '300px' : '0px')};
`;

const FinalMessage = styled.p`
    text-align: center;
`;

const SetReminderButton = styled(FilledButton)`
    /* override react markdown */
    p {
        margin: 0;
    }
`;

export const ExpertCTA = ({ options, analyticEventName }: ContactExpertCTAProps) => {
    let button = null;
    const [isVisible, setIsVisible] = useState<boolean>(true);

    const toggleVisibility = useCallback(() => {
        setIsVisible(!isVisible);
    }, [isVisible]);

    // TODO extract button creation logic into map
    switch (options.buttonType) {
        case ButtonCTATypes.CALL_BUTTON: {
            button = (
                <CallButton
                    buttonText={options.buttonText}
                    phoneNumber={options.phoneNumber ?? ''}
                    content={options.content}
                    subContent={options.subContent}
                    analyticEventName={analyticEventName}
                />
            );
            break;
        }
        case ButtonCTATypes.SET_REMINDER_BUTTON: {
            button = (
                <SetReminderButton
                    analyticEventName={analyticEventName}
                    analyticsExtras={{ buttonText: options.buttonText, description: options.description }}
                    onClick={toggleVisibility}
                >
                    <ReactMarkdown>{options.buttonText}</ReactMarkdown>
                </SetReminderButton>
            );
            break;
        }
        default:
            break;
    }
    return (
        <VisibilityWrapper isVisible={isVisible}>
            <ContactExpert description={options.description}>
                {isVisible ? button : <FinalMessage>Thanks!</FinalMessage>}
            </ContactExpert>
        </VisibilityWrapper>
    );
};
