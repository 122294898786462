type BreakPointsTypes<T = string> = {
    desktop: T;
    tablet: T;
    tabletBig: T;
    mobile: T;
    mobileBig: T;
    mobileMid: T;
};

export const BREAK_POINTS_DIMENSIONS_VALUES_IN_PX: BreakPointsTypes<number> = {
    desktop: 1024,
    tabletBig: 900,
    tablet: 768,
    mobileBig: 481,
    mobileMid: 375,
    mobile: 0,
};

const getMinWidthString = (width: number) => `(min-width: ${width}px)`;

export const BreakPoints: BreakPointsTypes = {
    desktop: getMinWidthString(BREAK_POINTS_DIMENSIONS_VALUES_IN_PX.desktop),
    tabletBig: getMinWidthString(BREAK_POINTS_DIMENSIONS_VALUES_IN_PX.tabletBig),
    tablet: getMinWidthString(BREAK_POINTS_DIMENSIONS_VALUES_IN_PX.tablet),
    mobileBig: getMinWidthString(BREAK_POINTS_DIMENSIONS_VALUES_IN_PX.mobileBig),
    mobileMid: getMinWidthString(BREAK_POINTS_DIMENSIONS_VALUES_IN_PX.mobileMid),
    mobile: getMinWidthString(BREAK_POINTS_DIMENSIONS_VALUES_IN_PX.mobile),
};
