import React from 'react';
import styled from '@emotion/styled';
import { mlabPrivacy, privacyUrl, termsUrl } from './constants';

type TextAlign = 'center' | 'left' | 'right';
type TextAlignProps = { textAlign?: TextAlign };

const SpeedTestLegalContainer = styled.div<TextAlignProps>`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 0.75rem;
    margin: 1rem 0 1rem 0;
    text-align: ${(props) => props.textAlign ?? 'center'};
    a {
        text-decoration: underline;
        color: #000;
    }
`;

interface SpeedTestLegalProps {
    textAlign?: TextAlign;
}
export const SpeedTestLegal = ({ textAlign = 'center' }: SpeedTestLegalProps) => (
    <div>
        <SpeedTestLegalContainer textAlign={textAlign}>
            We partner with Measurement Lab (M-Lab) to run this speed test. You can read M-Lab&apos;s{' '}
            <a href={mlabPrivacy} rel="noreferrer" target="_blank">
                privacy policy
            </a>
            .
        </SpeedTestLegalContainer>
        <SpeedTestLegalContainer textAlign={textAlign}>
            By running a speed test, you agree to {`Asurion's`}{' '}
            <a href={termsUrl} rel="noreferrer" target="_blank">
                Terms of Use
            </a>{' '}
            and{' '}
            <a href={privacyUrl} rel="noreferrer" target="_blank">
                Privacy Policy
            </a>
        </SpeedTestLegalContainer>
    </div>
);
