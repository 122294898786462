import styled from '@emotion/styled';
import { AnalyticEvents, usePageAnalytics } from '@home-mgmt-shared/analytics';
import { IAuthClient } from '@home-mgmt-shared/auth';
import { shuffle } from '@home-mgmt-shared/common-services';
import {
    BreakPoints,
    DownloadUploadSpeeds,
    FeedbackComponent,
    FeedbackTagLists,
    GoldStar,
    GrayStar,
    IconLink,
    IStreamingQualityGuideline,
    OutlineButton,
    SpeedTestResults,
    Wifi,
} from '@home-mgmt-shared/common-ui';
import { ExpertCTA, getDefaultCTAInfo, IExpertCTAInfo } from '@home-mgmt-shared/expert-cta';
import { formatSpeed } from '@home-mgmt-shared/web-scan';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import React, { useCallback, useRef } from 'react';
import { useTweekValue } from 'react-tweek';
import { DEFAULT_FEEDBACK_TAGS } from '../constants';
import { GenericResultsConfig } from '../models';

let AuthClient: IAuthClient;
if (IS_MX_APP) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires,global-require
    AuthClient = require('@home-mgmt-shared/auth').AuthClient;
}

const FeedbackModule = styled.div`
    margin-top: 30px;
    font-weight: 300;
    max-width: 365px;
    align-items: center;
`;

const ScanPageRunCompleted = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 365px;
    padding: 0 1rem;
    margin-left: auto;
    margin-right: auto;

    @media ${BreakPoints.desktop} {
        max-width: unset;
    }
`;

const CurrentSpeeds = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    display: flex;
    font-size: 20px;
    text-align: left;
    margin-bottom: 20px;
    max-width: 365px;
    width: 100%;
`;

const ResultsHeader = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    margin-top: 2rem;
    margin-bottom: 1.875rem;
    font-size: 2.5rem;
    text-align: center;
    display: flex;
    max-width: 465px;
    @media all and (max-width: 450px) {
        font-size: 2rem;
    }
`;

const ResultItem = styled.div`
    margin-top: 2rem;
    width: 100%;
`;

const FeedbackModuleContainer = styled.div`
    display: flex;
    justify-content: center;
    background: #f0f0f5;
    margin-top: 2rem;

    .feedback-module {
        margin-top: 0;
    }
`;

const DesktopContainer = styled.div`
    @media ${BreakPoints.desktop} {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0.5rem;
        width: 100%;
    }
`;

const IconLinkWrapper = styled.div`
    width: 100%;
    margin-top: 1.5rem;
    z-index: 999;
`;

interface RightColProps {
    rightChildElementCount?: number;
}

const DesktopColumnRight = styled.div<RightColProps>`
    @media ${BreakPoints.desktop} {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        ${(props) =>
            props.rightChildElementCount != null && props.rightChildElementCount > 0
                ? `margin-left: 2rem`
                : `display: none`}
    }
`;

const DesktopColumnLeft = styled.div<RightColProps>`
    @media ${BreakPoints.desktop} {
        display: flex;
        flex-direction: column;
        margin-right: 2rem;

        ${(props) =>
            props.rightChildElementCount != null && props.rightChildElementCount > 0
                ? `margin-right: 2rem`
                : `margin-right: 0`}
    }
`;

interface GenericResultsProps {
    config?: GenericResultsConfig;
    streamingGuidelines: IStreamingQualityGuideline[];
    resetSpeedTest: () => void;
    uploadSpeed: string;
    downloadSpeed: string;
}

export const GenericResults = ({
    config,
    streamingGuidelines,
    resetSpeedTest,
    uploadSpeed,
    downloadSpeed,
}: GenericResultsProps) => {
    usePageAnalytics('Generic Web Scan Results');
    const rightColumnRef = useRef<HTMLDivElement>(null);
    const rightChildElementCount = rightColumnRef?.current?.childElementCount;

    const isLoggedIn = AuthClient ? AuthClient.isLoggedIn : false;

    const expertCTAInfo: IExpertCTAInfo = useTweekValue('network_scan/expert_cta', getDefaultCTAInfo());

    const feedbackTagsUnsorted: FeedbackTagLists = useTweekValue(
        'network_scan/feedback_survey/feedback_tags',
        DEFAULT_FEEDBACK_TAGS,
    );

    const feedbackTagsRandomized: FeedbackTagLists = {
        maxScoreTagList: shuffle(feedbackTagsUnsorted.maxScoreTagList),
        lowScoreTagList: shuffle(feedbackTagsUnsorted.lowScoreTagList),
    };

    const onFeedbackClick = useCallback((eventName: string, props: Record<string, unknown>) => {
        analytics.dispatch(eventName, NSEventType.Click, props);
    }, []);

    const UpsellComponent = config?.upsellComponent;
    const CustomHeaderComponent = config?.CustomHeaderComponent;
    const CustomHeader = CustomHeaderComponent ? <CustomHeaderComponent /> : null;
    const checkSpeedAgainColumnLeft = config?.checkSpeedAgainButtonDesktopColumn === 'left' ?? false;
    const expertCTADesktopColumnLeft = config?.expertCTADesktopColumn === 'left' ?? false;

    const CheckSpeedAgainItem = () => (
        <ResultItem>
            <OutlineButton
                onClick={resetSpeedTest}
                role="button"
                tabIndex={0}
                aria-label="button to run speed test again"
                analyticEventName={AnalyticEvents.SCAN_AGAIN}
                borderwidth="1px"
            >
                Check speeds again
            </OutlineButton>
        </ResultItem>
    );

    return (
        <>
            <ScanPageRunCompleted>
                {isLoggedIn && (
                    <IconLinkWrapper>
                        <IconLink Icon={<Wifi />} msg="Go to Wi-Fi Overview" route="/overview" />
                    </IconLinkWrapper>
                )}
                <DesktopContainer>
                    <DesktopColumnLeft rightChildElementCount={rightChildElementCount}>
                        {CustomHeader}

                        {config?.resultsHeader && <ResultsHeader>{config.resultsHeader}</ResultsHeader>}
                        {config?.resultsSubHeader && <CurrentSpeeds>{config.resultsSubHeader}</CurrentSpeeds>}

                        <DownloadUploadSpeeds
                            status={SpeedTestStatus.completed}
                            downloadSpeed={formatSpeed(downloadSpeed)}
                            uploadSpeed={formatSpeed(uploadSpeed)}
                        />
                        {!!streamingGuidelines && (
                            <SpeedTestResults
                                downloadSpeed={formatSpeed(downloadSpeed)}
                                uploadSpeed={formatSpeed(uploadSpeed)}
                                streamingGuidelines={streamingGuidelines}
                            />
                        )}

                        {checkSpeedAgainColumnLeft && <CheckSpeedAgainItem />}
                        {expertCTADesktopColumnLeft && (
                            <ResultItem>
                                {config?.ctaOverride ?? (
                                    <ExpertCTA
                                        options={expertCTAInfo.good}
                                        analyticEventName={expertCTAInfo.good.analyticName}
                                    />
                                )}
                            </ResultItem>
                        )}
                    </DesktopColumnLeft>
                    <DesktopColumnRight rightChildElementCount={rightChildElementCount} ref={rightColumnRef}>
                        {UpsellComponent && <ResultItem>{UpsellComponent}</ResultItem>}
                        {!expertCTADesktopColumnLeft && (
                            <ResultItem>
                                {config?.ctaOverride ?? (
                                    <ExpertCTA
                                        options={expertCTAInfo.good}
                                        analyticEventName={expertCTAInfo.good.analyticName}
                                    />
                                )}
                            </ResultItem>
                        )}
                        {!checkSpeedAgainColumnLeft && <CheckSpeedAgainItem />}
                    </DesktopColumnRight>
                </DesktopContainer>
            </ScanPageRunCompleted>
            <FeedbackModuleContainer>
                <FeedbackModule className="feedback-module">
                    <FeedbackComponent
                        starRatingInfo={{
                            iconFilled: GoldStar(),
                            iconEmpty: GrayStar(),
                        }}
                        tagLists={feedbackTagsRandomized}
                        analyticsCb={onFeedbackClick}
                    />
                </FeedbackModule>
            </FeedbackModuleContainer>
        </>
    );
};
