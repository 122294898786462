import { Theme } from '@emotion/react';

export const bandwidthTheme: Theme = {
    font: {
        type: 'YahooSans',
        bold: 'YahooSans',
        accentColor: '#000000',
    },
    accentColors: {
        primary: '#6001D2',
        secondary: '#000000',
    },
    button: {
        primaryColor: '#6001D2',
        selectedColor: '#6001D2',
        disabledColor: '#E6E6EB',
        disabledTextColor: '#A5AAAF',
        defaultTextColor: '#000000',
    },
    messageAlert: {
        backgroundColor: '#F0F0F5',
        font: {
            type: 'YahooSans',
            bold: 'YahooSans',
            accentColor: '#000000',
        },
    },
    speedResults: {
        slowColor: '#FF0080',
        okayColor: '#FFA700',
        decentColor: '#11D3CD',
        goodColor: '#1AC567',
    },
    special: {
        primaryColor: '#000000',
        secondaryColor: '#6001D2',
    },
    spinner: {
        primaryColor: '',
    },
    progressBar: {
        primaryColor: '#6001D2',
        height: '0.5rem',
        backgroundColor: '#F0F0F5',
    },
    components: {
        button: {
            fontWeight: 500,
        },
        questionAnswer: {
            questionFontWeight: 'bold',
            answerAccent: '#000000',
        },
        questionAnswerGrid: {
            answerAccent: '#000000',
        },
        resultsPage: {
            header: {
                fontWeight: 'bold',
            },
            recommendation: {
                header: {
                    fontWeight: 'bold',
                },
            },
        },
    },
};
