import React from 'react';
import styled from '@emotion/styled';
import { SpeedTestListApproved } from './SpeedTestListApproved';
import { SpeedTestListWarnings } from './SpeedTestListWarnings';
import { IStreamingQualityGuideline } from './IStreamingQualityGuideline';

const H1 = styled.h1`
    font-size: 16px;
    font-family: ${(props) => props.theme.font?.type};
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
    text-transform: none;
    font-weight: bold;
`;

const SpeedTestResults = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    width: 100%;
    max-width: 365px;
`;

const LoadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    width: 100%;
`;

const checkCapabilities = (
    downloadSpeed: number,
    uploadSpeed: number,
    qualityGuidelines: IStreamingQualityGuideline[],
) => {
    const meetsCapabilities: string[] = [];
    const failedCapabilities: string[] = [];
    qualityGuidelines.forEach((stream: IStreamingQualityGuideline) => {
        if (downloadSpeed >= stream.downloadReq && uploadSpeed >= stream.uploadReq) {
            meetsCapabilities.push(stream.capability);
        } else {
            failedCapabilities.push(stream.capability);
        }
    });
    return {
        meetsCapabilities,
        failedCapabilities,
    };
};

interface SpeedTestResultsProps {
    downloadSpeed: number;
    uploadSpeed: number;
    streamQualityGuidelines: IStreamingQualityGuideline[];
    isLoading?: boolean;
}

export const SpeedCheckup = ({
    downloadSpeed,
    uploadSpeed,
    streamQualityGuidelines,
    isLoading,
}: SpeedTestResultsProps) => {
    const capabilities = checkCapabilities(downloadSpeed, uploadSpeed, streamQualityGuidelines);

    if (isLoading) return <LoadingWrapper />;

    return (
        <SpeedTestResults role="list" data-test-cy="speed-checkup-container">
            {capabilities.failedCapabilities.length > 0 && (
                <H1 data-test-cy="streaming-header-warning"> At your current speed, expect issues when you:</H1>
            )}
            {capabilities.failedCapabilities.map((capability) => (
                <SpeedTestListWarnings listItem={capability} key={Math.random()} />
            ))}
            {capabilities.meetsCapabilities.length > 0 && (
                <H1 data-test-cy="streaming-header-approved">At this speed, you can:</H1>
            )}
            {capabilities.meetsCapabilities.map((capability) => (
                <SpeedTestListApproved listItem={capability} key={Math.random()} />
            ))}
        </SpeedTestResults>
    );
};
