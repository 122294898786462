export enum AnalyticEvents {
    HOMEGRAPH_API_ERROR = 'Homegraph_Api_Error',
    LIST_SESSIONS_API_ERROR = 'List_Sessions_Api_Error',
    PAGE_EVENT = 'Page_Event',
    TRY_AGAIN_BUTTON = 'Retry_Data_Fetch_Button',
    EXPERT_CALL_BUTTON = 'Expert_Call_Button',
    CTA_BUTTON = 'CTA_Button',
    CONNECTION_EXPLANATION_ACCORDION = 'Connection_Explanation_Button',
    CONNECTION_EXPLANATION_ACCORDION_FEEDBACK = 'Connection_Explanation_Button_Feedback',
    CONNECTION_EXPLANATION_ACCORDION_SEE_MORE = 'Connection_Explanation_Button_See_More',
    SCAN_STARTED = 'Scan_Started',
    SCAN_CANCELED = 'Scan_Canceled',
    SCAN_AGAIN = 'Scan_Retry',
    SCAN_COMPLETED = 'Scan_Completed',
    COMPARE_SPEED_BUTTON = 'Compare_Speed_Button',
    COMPARE_SPEED_HELPFUL_FEEDBACK = 'Compare_Speed_Helpful_Feedback',
    INSIGHTS_SUBMIT_FEEDBACK = 'Insights_Submit_Feedback',
    INSIGHTS_HELPFUL_FEEDBACK = 'Insights_Helpful_Feedback',
    INSIGHTS_CAROUSEL_LEFT_BUTTON = 'Insights_Carousel_Left_Button',
    INSIGHTS_CAROUSEL_RIGHT_BUTTON = 'Insights_Carousel_Right_Button',
    UPDATE_VIEWED_INSIGHTS = 'Update_Viewed_Insights',
}
