import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { YahooFooter, YahooHeader } from '../components';

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 16px;
    background: #fff;
`;

const ContentContainer = styled.div`
    flex: 1;
`;

interface BasePageProps {
    children?: ReactNode;
    hideFooter?: boolean;
    hideHeader?: boolean;
}

export const BasePage = ({ children, hideFooter, hideHeader }: BasePageProps) => (
    <PageContainer>
        {!hideHeader && <YahooHeader />}
        <ContentContainer>{children}</ContentContainer>
        {!hideFooter && <YahooFooter />}
    </PageContainer>
);
