import * as React from 'react';

export const WifiSymbol = () => (
    <svg width="100%" height="100%" viewBox="0 0 94 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M54.785 51.638c0 5.137-3.808 8.851-7.969 8.851-4.161 0-7.969-3.714-7.969-8.85 0-5.138 3.808-8.851 7.969-8.851 4.161 0 7.968 3.714 7.968 8.85z"
            stroke="#fff"
            strokeWidth={7}
        />
        <path
            d="M19.468 39.288c3.868-5.293 14.918-15.88 28.177-15.88 13.26 0 23.205 10.587 26.52 15.88"
            stroke="#fff"
            strokeWidth={7}
            strokeLinecap="round"
        />
        <path
            d="M3.588 26.937C9.701 19.291 27.167 4 48.126 4c20.959 0 36.678 15.291 41.917 22.937"
            stroke="#fff"
            strokeWidth={7}
            strokeLinecap="round"
        />
    </svg>
);
