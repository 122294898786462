import { logToConsole } from './logConsole';
import { logToLoggingApi } from './loggingApi';
import { LogLevel } from './logLevels';
/**
 * support values are: error, warn, info, debug
 * @memberof logging
 * @enum {number}
 */
export const LOG_LEVELS: { [key in LogLevel]: number } = {
    error: 1,
    warn: 2,
    info: 3,
    debug: 4,
};

const MAX_LOG_LEVEL: LogLevel = 'info';

export const logHandler = (
    levelName: LogLevel,
    message: string,
    error?: string | Error,
    extraData?: Record<string, unknown>,
) => {
    const loggers =
        process.env?.REACT_APP_ENV === 'prod' || process.env?.REACT_APP_ENV === 'production'
            ? [logToLoggingApi]
            : [logToConsole, logToLoggingApi];
    try {
        if (LOG_LEVELS[MAX_LOG_LEVEL] < LOG_LEVELS[levelName]) {
            return Promise.resolve();
        }
        return Promise.all(loggers.map((log) => log(levelName, message, error, extraData)));
    } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('failed to log', err);
    }
    return Promise.reject();
};
