import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import React, { useEffect, useState } from 'react';
import { DownArrow, UpArrow } from '../Icons';

const SpeedAvailable = styled.div`
    color: ${(props) => props.theme.button?.defaultTextColor};
    font-size: 31px;
    display: flex;
    text-align: center;
    margin-left: 10px;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 450px) {
        font-size: 35px;
    }
`;

const SpeedTestFont = styled.div`
    font-family: ${(props) => props.theme.font?.type};
`;

type ContainerProps = {
    centered?: boolean;
    completed?: boolean;
};
const DownloadUploadSpeedsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${(props: ContainerProps) => (props.centered ? 'center' : 'left')};
    margin-top: 15px;
    min-height: 90px;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 365px;

    ${(props: ContainerProps) =>
        props.completed &&
        `
        min-height: 80px !important;
        @media all and (max-width: 465px) {
            justify-content: left;
        }`}
`;

const DownloadSection = styled.div`
    min-height: 80px;
    padding-right: 50px;

    @media all and (max-width: 465px) {
        padding-right: 40px;
    }

    @media all and (max-width: 400px) {
        padding-right: 25px;
    }

    @media all and (max-width: 360px) {
        padding-right: 8px;
    }
`;

const UploadSection = styled.div`
    min-height: 80px;
    padding-left: 50px;

    @media all and (max-width: 465px) {
        padding-left: 40px;
    }

    @media all and (max-width: 400px) {
        padding-left: 25px;
    }

    @media all and (max-width: 360px) {
        padding-left: 8px;
    }
`;

const Heading = styled(SpeedTestFont)`
    font-weight: bold;
    text-align: left;
    font-size: 14px;

    @media all and (max-width: 450px) {
        span {
            display: none;
        }
    }
`;

const SpeedNotAvailable = styled.div`
    display: flex;
    margin-top: 5px;
    font-size: 32px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #999999;
`;

const SpeedResult = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
`;

const Metric = styled.div`
    margin-top: 10px;
    margin-left: 10px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
`;

interface DownloadUploadSpeedsProps {
    status: string;
    downloadSpeed: number;
    uploadSpeed: number;
    centered?: boolean;
}
const DownloadUploadSpeeds = ({ status, downloadSpeed, uploadSpeed, centered }: DownloadUploadSpeedsProps) => {
    const [hasUploadStarted, setHasUploadStarted] = useState(false);
    const [hasDownloadStarted, setHasDownloadStarted] = useState(false);
    const theme = useTheme();
    useEffect(() => {
        if (status === SpeedTestStatus.inProgressUpload) {
            setHasUploadStarted(true);
        }

        if (status === SpeedTestStatus.inProgressDownload) {
            setHasDownloadStarted(true);
        }
    }, [status]);

    const downloadCompleted =
        (hasDownloadStarted && status !== SpeedTestStatus.inProgressDownload) || status === SpeedTestStatus.completed;
    const uploadCompleted =
        (hasUploadStarted && status !== SpeedTestStatus.inProgressUpload) || status === SpeedTestStatus.completed;

    return (
        <DownloadUploadSpeedsContainer completed={downloadCompleted} centered={centered}>
            <DownloadSection>
                <Heading>Download speed</Heading>
                {!downloadCompleted && (
                    <SpeedNotAvailable aria-label="Download speed test in progress">-</SpeedNotAvailable>
                )}

                {downloadCompleted && (
                    <SpeedResult>
                        <DownArrow fillColor={theme.special?.primaryColor} />
                        <SpeedAvailable aria-label={`download speed is${downloadSpeed} megabits per second`}>
                            {downloadSpeed}
                        </SpeedAvailable>
                        <Metric>Mbps</Metric>
                    </SpeedResult>
                )}
            </DownloadSection>
            <UploadSection>
                <Heading>Upload speed</Heading>
                {!uploadCompleted && (
                    <SpeedNotAvailable aria-label="Upload speed test in progress">-</SpeedNotAvailable>
                )}
                {uploadCompleted && (
                    <SpeedResult>
                        <UpArrow fillColor={theme.special?.primaryColor} />
                        <SpeedAvailable aria-label={`upload speed is${uploadSpeed} megabits per second`}>
                            {uploadSpeed}
                        </SpeedAvailable>
                        <Metric>Mbps</Metric>
                    </SpeedResult>
                )}
            </UploadSection>
        </DownloadUploadSpeedsContainer>
    );
};

export default DownloadUploadSpeeds;
