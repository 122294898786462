import { logHandler } from './logHandler';
import { LogLevel } from './logLevels';

let defaultContext: Record<string, unknown> = {};

const printLoggerErrorInfo = (message: string, data: unknown, err: unknown) =>
    // eslint-disable-next-line no-console
    console.log(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `Error occurred while trying to log message [${message}] with data [${data}].\nError [${err}]`,
    );

const doLog = (level: LogLevel, message: string, data?: Record<string, unknown>, error?: string | Error) => {
    try {
        const combinedData = { ...defaultContext, ...data };
        const extraData = Object.keys(combinedData).length > 0 ? combinedData : undefined;
        logHandler(level, message, error, extraData);
    } catch (err) {
        printLoggerErrorInfo(message, error, err);
    }
};

type Write = (message: string, data?: Record<string, unknown>) => void;
type WriteError = (message: string, error: string, data?: Record<string, unknown>) => void;

interface Logger {
    error: WriteError;
    warn: Write;
    debug: Write;
    info: Write;
    updateContext: (updater: (context: Record<string, unknown>) => Record<string, unknown>) => void;
}

export const logger: Logger = {
    /** log an error message
     * @memberof logging.logger
     * @param {string} message the message to log
     * @param {Record<string, unknown>} data the metadata to add to the message
     */
    error(logMessage: string, error: string, data?: Record<string, unknown>) {
        doLog('error', logMessage, data, error);
    },
    /** log a warning message
     * @memberof logging.logger
     * @param {string} message the message to log
     * @param {Record<string, unknown>} data the metadata to add to the message
     */
    warn(message: string, data?: Record<string, unknown>) {
        doLog('warn', message, data);
    },
    /** log an info message
     * @memberof logging.logger
     * @param {string} message the message to log
     * @param {Record<string, unknown>} data the metadata to add to the message
     */
    info(message: string, data?: Record<string, unknown>) {
        doLog('info', message, data);
    },
    /** log a debug message
     * @memberof logging.logger
     * @param {string} message the message to log
     * @param {Record<string, unknown>} data the metadata to add to the message
     */
    debug(message: string, data?: Record<string, unknown>) {
        doLog('debug', message, data);
    },
    updateContext(updater: (context: Record<string, unknown>) => Record<string, unknown>) {
        defaultContext = updater(defaultContext);
    },
};

export const init = (context: Record<string, unknown>) => {
    defaultContext = context;
};
