import deepEqual from 'deep-equal';
import { prepareKey } from 'react-tweek';
import { BehaviorSubject } from 'rxjs';
import { Context, createTweekClient } from 'tweek-client';
import { TweekRepository } from 'tweek-local-cache';

prepareKey('network_scan/_');

const tweekClient = createTweekClient({
    baseServiceUrl: 'https://tweek.mysoluto.com',
});

export const defaultTweekRepository: TweekRepository = new TweekRepository({
    client: tweekClient,
    context: { network_scan: {} },
});

const tweekRepositorySubject = new BehaviorSubject<TweekRepository>(defaultTweekRepository);

export const tweekRepository$ = tweekRepositorySubject.asObservable();

// eslint-disable-next-line @typescript-eslint/ban-types
export const updateTweekContext = (extraContext: {}) => {
    tweekRepositorySubject.value.updateContext((currentContext: Context) =>
        deepEqual(extraContext, currentContext) ? null : { ...currentContext, network_scan: { ...extraContext } },
    );
};
