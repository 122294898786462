import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import { FeedbackTags } from './FeedbackTags';
import { OpenFeedback } from './OpenFeedback';
import { IStarRatingInfo, StarRating } from './StarRating';
import { SubmitButton } from './SubmitButton';

export interface FeedbackTagLists {
    headerMsgStart?: string;
    headerMsgComplete?: string;
    maxScoreTagList: string[];
    lowScoreTagList: string[];
}

interface IFeedbackComponentProps {
    starRatingInfo: IStarRatingInfo;
    tagLists: FeedbackTagLists;
    analyticsCb?: (eventName: string, props: Record<string, unknown>) => void;
    noBackground?: boolean;
    titleStart?: string;
    titleComplete?: string;
}

interface Background {
    noBackground?: boolean;
}

const FontWrapper = styled.div`
    width: 100%;
    font-family: ${(props) => props.theme.font?.type};
`;

const FeedbackContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: ${(props: Background) => (props.noBackground ? 'none' : '#f0f0f5')};
    padding-top: 40px;
    padding-bottom: 30px;
`;

const Header = styled.h1`
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 1.875rem;
    margin-top: 0;
    text-transform: none;
`;
const Title = styled.p`
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 0.3125rem;
`;
const Subtitle = styled.p`
    font-size: 0.875rem;
    text-align: center;
    margin-top: 0.3125rem;
`;

const CenterContainer = styled.div`
    max-width: 22.8125rem;
`;

type FeedbackBodyProps = {
    isSubmitted?: boolean;
    rating: number;
};
const FeedbackBodyContainer = styled.div`
    transition: max-height 0.4s ease-in-out;
    height: auto;
    max-height: ${(props: FeedbackBodyProps) => (!props.isSubmitted && props.rating > 0 ? '900px' : '0px')};
    overflow: scroll;
    display: flex;
    flex-direction: column;
`;

const handleFeedbackTagUpdate = (feedbackTags: string[], selectedTag: string): string[] => {
    // if tag isn't in list, then we add it
    let list = feedbackTags.slice();
    if (!list.includes(selectedTag)) {
        list.push(selectedTag);
    } else {
        // if tag is already in list, then we remove it
        list = list.filter((item) => item !== selectedTag);
    }
    return list;
};

export const FeedbackComponent = ({
    starRatingInfo,
    tagLists,
    analyticsCb,
    noBackground,
    titleStart,
    titleComplete,
}: IFeedbackComponentProps) => {
    const [rating, setRating] = useState(0);
    const [selectedFeedbackTags, setSelectedFeedbackTags] = useState<string[]>([]);
    const [feedbackText, setFeedbackText] = useState<string>('');
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const toggleStar = useCallback(
        (selected: number) => {
            if (isSubmitted) {
                return;
            }
            analyticsCb?.('Star_Rating_Updated', { rating: selected });
            setRating(selected);
            setSelectedFeedbackTags([]);
        },
        [analyticsCb, isSubmitted],
    );

    const toggleFeedbackTag = useCallback(
        (selected: string) => {
            const newFeedbackList = handleFeedbackTagUpdate(selectedFeedbackTags, selected);
            setSelectedFeedbackTags(newFeedbackList);
            analyticsCb?.('Feedback_Tag_Updated', { rating, selectedFeedbackTags: newFeedbackList });
        },
        [analyticsCb, rating, selectedFeedbackTags],
    );
    const textFormChanged = useCallback((text: string) => {
        setFeedbackText(text);
    }, []);
    const submitFeedback = useCallback(() => {
        setIsSubmitted(true);
        analyticsCb?.('Submit_Feedback', { rating, selectedFeedbackTags, feedbackText });
    }, [analyticsCb, feedbackText, rating, selectedFeedbackTags]);

    return (
        <FontWrapper>
            <FeedbackContainer noBackground={noBackground}>
                <CenterContainer>
                    <Header data-test-cy="feedback-survey-title" style={titleStart ? { fontWeight: 'normal' } : {}}>
                        {isSubmitted
                            ? titleComplete || 'Thanks for your feedback!'
                            : titleStart || 'How was your Wi-Fi checkup?'}
                    </Header>
                    <StarRating starRatingInfo={starRatingInfo} onClick={toggleStar} rating={rating} />
                    <FeedbackBodyContainer
                        tabIndex={0}
                        data-test-cy="feedback-survey-body"
                        rating={rating}
                        isSubmitted={isSubmitted}
                    >
                        <Title data-test-cy="feedback-survey-prompt">
                            {rating === 5 ? 'What did you find most helpful?' : 'What could be better?'}
                        </Title>
                        <Subtitle data-test-cy="feedback-survey-instruction">Select as many as you like.</Subtitle>
                        <FeedbackTags
                            isVisible={rating > 0}
                            selectedTags={selectedFeedbackTags}
                            onClick={toggleFeedbackTag}
                            tagList={rating === 5 ? tagLists.maxScoreTagList : tagLists.lowScoreTagList}
                        />
                        <OpenFeedback isVisible={rating > 0} onChange={textFormChanged} />
                        <SubmitButton isVisible={rating > 0} onClick={submitFeedback} label="Submit" />
                    </FeedbackBodyContainer>
                </CenterContainer>
            </FeedbackContainer>
        </FontWrapper>
    );
};
