import styled from '@emotion/styled';
import React from 'react';

const HeaderContent = styled.div`
    height: 3.5rem;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    max-width: 365px;
`;

const Hyperlink = styled.a`
    color: ${(props) => props.theme.accentColors?.primary};
    font-weight: bold;
    text-decoration: none;
`;

const Text = styled.p`
    line-height: 150%;
`;

export const YahooDisclaimer = () => (
    <HeaderContent>
        <Text>
            For Yahoo account access or email support, please visit{' '}
            <Hyperlink href="https://help.yahoo.com/" target="_blank">
                help.yahoo.com
            </Hyperlink>
        </Text>
    </HeaderContent>
);
