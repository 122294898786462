import styled from '@emotion/styled';
import { WebscanEvents } from '@home-mgmt-shared/analytics';
import { DownloadUploadSpeeds, OutlineButton, SpeedProgress, SpeedTestHomeIntro } from '@home-mgmt-shared/common-ui';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import { SpeedTestStatus, WixiCore, WixiEnrollment, WixiSnap } from '@soluto-private/wixi-web-sdk';
import React, { useCallback, useEffect, useState } from 'react';
import {
    useSession as useWixiSession,
    useSpeedTestDownloadSpeed,
    useSpeedTestStatus,
    useSpeedTestUploadSpeed,
} from '../hooks';
import { IScanResults, WebScanState } from '../models';
import { formatSpeed, getProgressData } from '../utils';

const defaultHeaderMsg = 'Speed test in progress';

interface WebScanProps {
    customEnrollment?: WixiEnrollment;
    onScanComplete?: (results: IScanResults) => void;
    onScanStart?: () => void;
    onScanCancel?: () => void;
    IntroImage?: () => JSX.Element;
    IntroPageComponent?: (props: { startSpeedTest: () => Promise<void> }) => JSX.Element;
    showHeader?: boolean;
    headerMsg?: string;
}

const SpeedTestContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

const SpeedTestProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
`;

const HeaderMsg = styled.h1`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 2.5rem;
    display: flex;
`;
export const WebScan = ({
    customEnrollment,
    onScanComplete,
    onScanStart,
    onScanCancel,
    IntroImage,
    IntroPageComponent,
    showHeader = true,
    headerMsg = defaultHeaderMsg,
}: WebScanProps) => {
    const [webScanState, setWebScanState] = useState(WebScanState.NOT_STARTED);
    const wixiSession = useWixiSession();
    const uploadSpeed = useSpeedTestUploadSpeed();
    const downloadSpeed = useSpeedTestDownloadSpeed();

    const onExperimentalFailure = useCallback((eventName: string, extraInfo?: Record<string, unknown>) => {
        analytics.dispatch(eventName, NSEventType.Error, extraInfo);
    }, []);

    const [snap] = useState<WixiSnap>(
        new WixiSnap({
            wixiHealthConfig: {
                onExperimentalFailure,
            },
        }),
    );
    const userId = customEnrollment?.clientId;

    const onSnapStarted = useCallback(() => {
        setWebScanState(WebScanState.STARTED);
    }, []);

    const speedTestStatus = useSpeedTestStatus();
    const [scanStatus, setScanStatus] = useState<SpeedTestStatus>(speedTestStatus);
    const scanCompleted = scanStatus === SpeedTestStatus.completed;

    useEffect(() => {
        setScanStatus(speedTestStatus);
    }, [speedTestStatus]);

    useEffect(() => {
        if (wixiSession.goId) {
            analytics.updateProperties({
                userId: userId || WixiCore.clientId,
                scanId: wixiSession.goId,
                wixiClientId: WixiCore.clientId,
            });
            analytics.dispatch(WebscanEvents.ScanStarted, NSEventType.Click, {
                userId: userId || WixiCore.clientId,
                scanId: wixiSession.goId,
                wixiClientId: WixiCore.clientId,
            });
        }
    }, [userId, wixiSession.goId]);

    useEffect(() => {
        if (scanCompleted) {
            const results: IScanResults = {
                clientId: WixiCore.clientId,
                goId: wixiSession.goId || '',
                uploadSpeed,
                downloadSpeed,
            };
            setWebScanState(WebScanState.FINISHED);
            onScanComplete?.(results);
        }
    }, [downloadSpeed, onScanComplete, scanCompleted, uploadSpeed, wixiSession.goId]);

    const startSpeedTest = useCallback(async () => {
        setWebScanState(WebScanState.INITIALIZING);
        if (snap) snap.onSnapStart = onSnapStarted;
        await snap?.snap();
        onScanStart?.();
    }, [snap, onSnapStarted, onScanStart]);

    const cancelSpeedTest = useCallback(() => {
        if (wixiSession.goId) {
            analytics.dispatch(WebscanEvents.ScanCanceled, NSEventType.Click, {
                userId: userId || WixiCore.clientId,
                scanId: wixiSession.goId,
                wixiClientId: WixiCore.clientId,
            });
        }
        snap?.cancel();
        onScanCancel?.();
        setWebScanState(WebScanState.NOT_STARTED);
    }, [onScanCancel, snap, userId, wixiSession.goId]);

    const displayIntro = webScanState === WebScanState.NOT_STARTED;
    const displayInProgress = webScanState === WebScanState.INITIALIZING || webScanState === WebScanState.STARTED;

    const IntroComponent = IntroPageComponent ? (
        <IntroPageComponent startSpeedTest={startSpeedTest} />
    ) : (
        <SpeedTestHomeIntro startSpeedTest={startSpeedTest} IntroImage={IntroImage} />
    );
    return (
        <>
            {displayIntro && <>{IntroComponent}</>}
            {displayInProgress && (
                <SpeedTestContainer>
                    <SpeedTestProgressContainer>
                        {showHeader && <HeaderMsg>{headerMsg}</HeaderMsg>}
                        <SpeedProgress progressData={getProgressData(scanStatus, uploadSpeed, downloadSpeed)} />
                        <DownloadUploadSpeeds
                            status={scanStatus}
                            downloadSpeed={formatSpeed(downloadSpeed)}
                            uploadSpeed={formatSpeed(uploadSpeed)}
                        />
                        <OutlineButton
                            analyticEventName={WebscanEvents.ScanCanceled}
                            data-test-cy="speed-test-cancel-button"
                            onClick={cancelSpeedTest}
                            aria-label="button to cancel speed test"
                            tabIndex={0}
                            borderwidth="1px"
                        >
                            Cancel
                        </OutlineButton>
                    </SpeedTestProgressContainer>
                </SpeedTestContainer>
            )}
        </>
    );
};
