import { IWixiSession, WixiSession } from '@soluto-private/wixi-web-sdk';
import { useEffect, useState } from 'react';

export const useSession = (): IWixiSession => {
    const [session, setSession] = useState<IWixiSession>({});

    useEffect(() => {
        WixiSession.onSessionChange = (wixiSession: IWixiSession) => {
            setSession(wixiSession);
        };

        return () => {
            WixiSession.onSessionChange = undefined;
        };
    }, []);

    return session;
};

export const useHealthId = (): string => {
    const [healthId, setHealthId] = useState<string>('');

    useEffect(() => {
        WixiSession.onHealthIdChange = (id: string) => {
            setHealthId(id);
        };

        return () => {
            WixiSession.onHealthIdChange = undefined;
        };
    }, []);

    return healthId;
};
