import styled from '@emotion/styled';
import { usePageAnalytics } from '@home-mgmt-shared/analytics';
import React from 'react';
import { BreakPoints } from '../Breakpoints';
import { FilledButton } from '../Buttons';
import { SpeedTestLegal } from '../SpeedTest';

const IntroPageHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 2rem 2rem;
    max-width: 19rem;

    @media ${BreakPoints.mobileBig} {
        max-width: 25rem;
    }
`;

const SubHeader = styled.div`
    font-size: 1.5rem;
    text-align: center;
`;

const LifeStyleImage = styled.img`
    border-radius: 50%;
    width: 170px;
    height: 170px;

    @media ${BreakPoints.mobileBig} {
        /* max-width: 20rem;  */
        width: 248px;
        height: 248px;
    }
`;

const LifeStyleBackgroundDiv = styled.div`
    border-radius: 50%;
    padding: 1rem;
    width: 80px;
    height: 80px;
    background-color: ${(props) => props.theme.accentColors?.primary};

    @media ${BreakPoints.mobileBig} {
        width: 110px;
        height: 110px;
    }
`;

interface IntroWithHeaderProps {
    headingText?: string;
    subHeadingText?: string;
    nextButtonText?: string;
    nextButtonOnClick?: () => void;
    lifestyleImage: string;
    onPageLoadAnalytic?: string;
    nextButtonAnalytic?: string;
    BackgroundCircleImage: () => JSX.Element;
}

const Block = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 2rem 0;
    overflow: hidden;
`;

const Background = styled.div`
    box-sizing: border-box;
    flex: none;

    margin-left: -60%;
    margin-top: 72px;

    @media ${BreakPoints.mobileBig} {
        margin-left: -55%;
        margin-top: 106px;
    }
`;

const Foreground = styled.div`
    box-sizing: border-box;
    width: 100%;
    flex: none;
    z-index: 101;
`;

export const LifestyleIntro = ({
    headingText = '',
    subHeadingText = '',
    nextButtonText = 'Start speed test',
    nextButtonOnClick,
    lifestyleImage,
    onPageLoadAnalytic = '',
    nextButtonAnalytic = '',
    BackgroundCircleImage,
}: IntroWithHeaderProps) => {
    usePageAnalytics(onPageLoadAnalytic);
    return (
        <Content>
            <IntroPageHeader>{headingText}</IntroPageHeader>
            <SubHeader>{subHeadingText}</SubHeader>
            <Block>
                <Foreground>
                    <LifeStyleImage src={lifestyleImage} />
                </Foreground>
                <Background>
                    <LifeStyleBackgroundDiv>{BackgroundCircleImage()}</LifeStyleBackgroundDiv>
                </Background>
            </Block>

            <div>
                <FilledButton
                    data-test-cy="speed-test-home-start-button"
                    analyticEventName={nextButtonAnalytic}
                    onClick={nextButtonOnClick}
                >
                    {nextButtonText}
                </FilledButton>
            </div>
            <div>
                <SpeedTestLegal textAlign="left" />
            </div>
        </Content>
    );
};
