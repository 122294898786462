import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import { ExpertPhoto } from './ExpertPhoto';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 365px;
`;

const TextBubble = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    background: #f0f0f5;
    border-radius: 37px;
    padding: 0 1.75rem;
    padding-top: 1rem;
`;

const ExpertHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: left;
    align-self: flex-start;
    padding: 0 1.75rem;
    height: 1.75rem;
    overflow-y: visible;
`;

const ImgContainer = styled.div`
    position: relative;
    width: 2.75rem;
    height: 2.75rem;
`;

const ExpertImage = styled.div`
    width: 100%;
    height: 100%;
`;

const ExpertTitle = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    line-height: 200%;
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: 1rem;

    @media all and (max-width: 310px) {
        font-size: 0.9rem;
    }
`;

type ContactExpertProps = {
    children: ReactNode;
    description: string;
};

export const ContactExpert = ({ children, description }: ContactExpertProps) => (
    <Container>
        <ExpertHeader>
            <ImgContainer>
                <ExpertImage>
                    <ExpertPhoto />
                </ExpertImage>
            </ImgContainer>
            <ExpertTitle>
                John,&nbsp;<strong>your Wi-Fi expert</strong>
            </ExpertTitle>
        </ExpertHeader>
        <TextBubble>
            <ReactMarkdown>{description}</ReactMarkdown>
            {children}
        </TextBubble>
    </Container>
);
