import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import styled from '@emotion/styled';
import { BaseButton } from '../Buttons';

interface IBaseButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    onClick: () => void;
    label: string;
    disabled?: boolean;
    analyticEventName?: string;
    analyticsExtras?: Record<string, unknown>;
    isVisible?: boolean;
}

const Button = styled(BaseButton)`
    background: ${(props) => (props.disabled ? props.theme.button?.disabledColor : props.theme.button?.primaryColor)};
    border-radius: 25px;
    color: white;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    margin-top: 0.9375rem;
    border: none;
    height: 46px;
    transition: transform 0.2s ease-in;
    user-select: none;
    font-weight: ${(props) => props.theme.components?.button?.fontWeight || 'normal'};

    button&:active {
        transform: scale(0.96);
    }
    button&:hover,
    margin-left: 1rem;
    margin-right: 1rem;
`;

export const SubmitButton = ({
    onClick,
    label,
    analyticEventName,
    analyticsExtras,
    disabled,
    isVisible,
}: IBaseButtonProps) => (
    <Button
        tabIndex={isVisible ? 0 : -1}
        analyticEventName={analyticEventName ?? 'Generic_Submit_Button'}
        analyticsExtras={analyticsExtras}
        data-test-cy="feedback-survey-submit"
        type="button"
        disabled={disabled}
        onClick={onClick}
    >
        {label}
    </Button>
);
