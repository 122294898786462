import styled from '@emotion/styled';
import { WebscanEvents } from '@home-mgmt-shared/analytics';
import { IStreamingQualityGuideline } from '@home-mgmt-shared/common-ui';
import { IScanResults, WebScan } from '@home-mgmt-shared/web-scan';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTweekValue } from 'react-tweek';
import { GenericResults } from '../components';
import { DEFAULT_STREAMING_GUIDELINES } from '../constants';
import { GenericScanConfig, PageState } from '../models';

const ScanPageRun = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

const ScanPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 16px;
    background: #fff;
`;

interface GenericScanViewProps {
    config: GenericScanConfig;
    onPageChange?: (page: PageState) => void;
}
export const USER_ID = 'userId';

export const GenericScanView = ({ config, onPageChange }: GenericScanViewProps) => {
    const [scanCompleted, setScanCompleted] = useState(false);
    const [speedResults, setSpeedResults] = useState<IScanResults>();

    useEffect(() => {
        if (scanCompleted) {
            onPageChange?.(PageState.RESULTS);
            return;
        }

        onPageChange?.(PageState.SCAN);
    }, [onPageChange, scanCompleted]);

    const streamingGuidelines: IStreamingQualityGuideline[] = useTweekValue(
        'network_scan/capabilities/streaming_capabilities',
        DEFAULT_STREAMING_GUIDELINES,
    );

    const onScanComplete = useCallback((results: IScanResults) => {
        setScanCompleted(true);
        setSpeedResults(results);

        analytics.dispatch(WebscanEvents.ScanCompleted, NSEventType.Event, {
            userId: results.clientId,
            scanId: results.goId,
            uploadSpeed: results.uploadSpeed,
            downloadSpeed: results.downloadSpeed,
        });
    }, []);

    const onScanAgain = useCallback(() => {
        setScanCompleted(false);
    }, []);

    return (
        <>
            <Helmet>
                <title>Wi-Fi checkup</title>
            </Helmet>
            <ScanPageContainer>
                {!scanCompleted && (
                    <ScanPageRun>
                        <WebScan
                            IntroImage={config.webscan?.IntroImage}
                            onScanComplete={onScanComplete}
                            IntroPageComponent={config.webscan?.IntroComponent}
                            showHeader={config.webscan?.scanningPage?.showHeader}
                            headerMsg={config.webscan?.scanningPage?.headerMsg}
                        />
                    </ScanPageRun>
                )}

                {scanCompleted && (
                    <>
                        <GenericResults
                            downloadSpeed={speedResults?.downloadSpeed ?? ''}
                            uploadSpeed={speedResults?.uploadSpeed ?? ''}
                            resetSpeedTest={onScanAgain}
                            streamingGuidelines={streamingGuidelines}
                            config={config?.results}
                        />
                    </>
                )}
            </ScanPageContainer>
        </>
    );
};
