/* eslint-disable no-console */
import { LogLevel } from './logLevels';

export const logToConsole = (
    level: LogLevel,
    message: string,
    error?: string | Error,
    extraData?: Record<string, unknown>,
) => {
    let err = '';
    if (error) {
        if (error instanceof Error) {
            err = `Error: ${error.message}`;
        } else {
            err = `Error: ${error}`;
        }
    }

    const logMessage = `[WixiLogger] ${message}`;

    const data = extraData ?? '';

    switch (level) {
        case 'error':
            console.error(logMessage, err, data);
            break;
        case 'warn':
            console.warn(logMessage, err, data);
            break;
        case 'debug':
            console.debug(logMessage, err, data);
            break;
        case 'info':
        default:
            console.info(logMessage, err, data);
    }
};
