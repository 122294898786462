// default tweek values
export const DEFAULT_FEEDBACK_TAGS = {
    maxScoreTagList: ['Fast results', 'Easy to use', 'Easy to understand', 'Fixed my issue', 'Learned something new'],
    lowScoreTagList: [
        'Make it faster',
        'Make it easier to use',
        'Make it easier to understand',
        'Fix my issue',
        'Teach me something new',
    ],
};

export const DEFAULT_STREAMING_GUIDELINES = [
    { capability: 'Email', downloadReq: 1, uploadReq: 0 },
    { capability: 'Browse the web', downloadReq: 3, uploadReq: 0 },
    { capability: 'Game online', downloadReq: 5, uploadReq: 0 },
    { capability: 'Video chat', downloadReq: 1, uploadReq: 1 },
    { capability: 'Video conference', downloadReq: 5, uploadReq: 2 },
    {
        capability: 'Stream HD video on a single device',
        downloadReq: 5,
        uploadReq: 0,
    },
    {
        capability: 'Stream HD video on multiple devices',
        downloadReq: 10,
        uploadReq: 0,
    },
    {
        capability: 'Stream 4k video on a single device',
        downloadReq: 25,
        uploadReq: 0,
    },
];
