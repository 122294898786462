export interface IExpertCTAOptions {
    description: string;
    buttonText: string;
    buttonType: ButtonCTATypes;
    content: string;
    analyticName: string;
    phoneNumber?: string;
    subContent?: string;
}

export enum ButtonCTATypes {
    CALL_BUTTON = 'CallButton',
    SET_REMINDER_BUTTON = 'SetReminderButton',
}

export type IExpertCTAInfo = {
    [type in CTAStatus]: IExpertCTAOptions;
};

export type CTAStatus = 'good' | 'decent' | 'okay' | 'slow';

export const getDefaultCTAInfo = (): IExpertCTAInfo => ({
    good: {
        description: 'Would you like a reminder to check your speed next week to see if it has improved?',
        buttonText: 'Yes, remind me later',
        buttonType: ButtonCTATypes.SET_REMINDER_BUTTON,
        content: '',
        analyticName: 'Expert_Set_Reminder_Button',
    },
    decent: {
        description: 'Would you like a reminder to check your speed next week to see if it has improved?',
        buttonText: 'Yes, remind me later',
        buttonType: ButtonCTATypes.SET_REMINDER_BUTTON,
        content: '',
        analyticName: 'Expert_Set_Reminder_Button',
    },
    okay: {
        description: 'Would you like a reminder to check your speed next week to see if it has improved?',
        buttonText: 'Yes, remind me later',
        buttonType: ButtonCTATypes.SET_REMINDER_BUTTON,
        content: '',
        analyticName: 'Expert_Set_Reminder_Button',
    },
    slow: {
        description: 'Would you like a reminder to check your speed next week to see if it has improved?',
        buttonText: 'Yes, remind me later',
        buttonType: ButtonCTATypes.SET_REMINDER_BUTTON,
        content: '',
        analyticName: 'Expert_Set_Reminder_Button',
    },
});
