import styled from '@emotion/styled';
import React from 'react';

const NUM_ICONS = 5;

export interface IStarRatingInfo {
    iconFilled: JSX.Element;
    iconEmpty: JSX.Element;
}

interface IStarSurveyProps {
    starRatingInfo: IStarRatingInfo;
    onClick: (selected: number) => void;
    rating: number;
}

const StarRow = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.9375rem;
`;

const IconContainer = styled.button`
    margin: 0rem 0.625rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
`;

const createIconList = (rating: number, starRatingInfo: IStarRatingInfo): JSX.Element[] => {
    const iconList: JSX.Element[] = [];
    for (let i = 1; i < NUM_ICONS + 1; i++) {
        iconList.push(i <= rating ? starRatingInfo.iconFilled : starRatingInfo.iconEmpty);
    }
    return iconList;
};

export const StarRating = ({ starRatingInfo, onClick, rating }: IStarSurveyProps) => {
    const iconList = createIconList(rating, starRatingInfo);
    return (
        <StarRow>
            {iconList.map((icon, index) => (
                /* we don't have anything else to use for the key and elements shouldn't be added/removed
                   so we shouldn't be penalized with extra renders
                 */
                <IconContainer
                    tabIndex={0}
                    aria-label={`${index + 1} star rating`}
                    data-test-cy={`feedback-survey-star${index + 1}`}
                    key={index + 1}
                    onClick={() => onClick(index + 1)}
                >
                    {icon}
                </IconContainer>
            ))}
        </StarRow>
    );
};
