import React from 'react';
import styled from '@emotion/styled';
import SpinnerSvg from './yahooSpinner.svg';

const SpinnerContainer = styled.div`
    text-align: center;
    margin: 1rem 0;
`;

const Spinner = styled.img`
    animation: spin 4s linear infinite;
    -webkit-animation: spin 1.5s linear infinite;

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
`;

export const YahooSpinner = () => (
    <SpinnerContainer>
        <Spinner alt="spinner" src={SpinnerSvg} />
    </SpinnerContainer>
);
