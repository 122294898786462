import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import { getCurrentSessionURL, init, identify } from '@fullstory/browser';
import { fullStoryConstants } from './constants';

const fullStorySessionCreationEvent = (eventType: string) => {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    ((window as unknown) as { [key: string]: unknown })['_fs_ready'] = () => {
        const fullstorySessionUrl = getCurrentSessionURL(true);
        if (fullstorySessionUrl) {
            analytics.dispatch('Fullstory_Session_Created', NSEventType.FullStory, {
                fullstorySessionUrl,
                eventType,
            });
            analytics.updateProperties({ fullstorySessionUrl });
        }
    };
};

export const initFullstory = (devMode: boolean) => {
    init({ orgId: fullStoryConstants.orgKey, devMode });
};

export const setIdentityForFullStory = (userId: string, props?: Record<string, string>) => {
    try {
        identify(userId, { ...props });
        fullStorySessionCreationEvent('Fullstory_User_Updated');
        // eslint-disable-next-line no-empty
    } catch (error) {}
};
