import styled from '@emotion/styled';
import { WebscanEvents } from '@home-mgmt-shared/analytics';
import React, { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { CSSTransition } from 'react-transition-group';
import { FilledButton } from '../Buttons';
import { HomeOffice } from '../Images';
import { mlabPrivacy, privacyUrl, termsUrl } from './constants';

const StartButton = styled(FilledButton)`
    background-color: ${(props) => props.theme.button?.primaryColor};
    color: #ffffff;
    font-family: ${(props) => props.theme.font?.type};
    max-width: 15rem;
`;

const IntroWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 0.5rem;
`;

const HomeOfficeImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    max-width: 330px;

    @media all and (max-width: 329px) {
        max-width: none;
    }
`;

const Header = styled.div`
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 2rem;
    font-family: ${(props) => props.theme.font?.type};
    line-height: 150%;
`;

const SpeedTestLegal = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 0.75rem;
    margin: 1.5rem 0 1rem 0;
    text-align: center;
    a {
        text-decoration: underline;
        color: #000;
    }
`;

interface SpeedTestHomeIntroProps {
    startSpeedTest: () => void;
    headerText?: string;
    IntroImage?: () => JSX.Element;
}

export const SpeedTestHomeIntro = ({ startSpeedTest, headerText, IntroImage }: SpeedTestHomeIntroProps) => {
    const [render, setRender] = useState(false);
    useEffect(() => {
        setRender(true);
    }, []);
    return (
        <>
            <IntroWrapper>
                <CSSTransition
                    in={render}
                    timeout={{
                        appear: 1000,
                        enter: 1000,
                    }}
                    appear
                    unmountOnExit
                    classNames="fade"
                >
                    <Header>{headerText ?? `Make sure you’re connected to your home Wi-Fi to get started.`}</Header>
                </CSSTransition>
                <HomeOfficeImgWrapper>{IntroImage ? IntroImage() : <HomeOffice />}</HomeOfficeImgWrapper>
                <CSSTransition
                    in={render}
                    timeout={{
                        appear: 0,
                        enter: 1000,
                    }}
                    appear
                    unmountOnExit
                    classNames="fade"
                >
                    <StartButton
                        analyticEventName={WebscanEvents.ScanStarted}
                        data-test-cy="speed-test-home-start-button"
                        onClick={startSpeedTest}
                        aria-label="start speed test button to test internet download and upload speeds"
                        role="button"
                    >
                        Start speed test
                    </StartButton>
                </CSSTransition>
            </IntroWrapper>
            <SpeedTestLegal>
                We partner with Measurement Lab (M-Lab) to run this speed test. You can read M-Lab&apos;s privacy policy{' '}
                <a href={mlabPrivacy} rel="noreferrer" target="_blank">
                    here
                </a>
                .
            </SpeedTestLegal>
            <SpeedTestLegal>
                By running a speed test, you agree to our{' '}
                <a href={termsUrl} rel="noreferrer" target="_blank">
                    Terms of Use
                </a>{' '}
                and{' '}
                <a href={privacyUrl} rel="noreferrer" target="_blank">
                    Privacy Policy
                </a>
            </SpeedTestLegal>
        </>
    );
};
