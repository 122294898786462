import * as React from 'react';

export const ChevronRight = () => (
    <svg
        width={7}
        height={11}
        viewBox="0 0 7 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="chevron right"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.601 1.657l4.167 3.825L.601 9.307l1.175 1.175 5-5-5-5L.601 1.657z"
            fill="#fff"
        />
    </svg>
);
